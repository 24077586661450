import service from "@/api/service";
import { DetailRequest, RegisterRequest } from "@/api/setting-ticket/request";
import { DetailResponse } from "@/api/setting-ticket/response";
import { CommonResponse } from "@/api/response";

/**
 * デジタルチケット設定情報取得APIをコールします。
 *
 * @param detailRequest スタンプ検索のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/get-setting-ticket", detailRequest);
  return response.data as DetailResponse;
}

/**
 * デジタルチケット設定登録更新APIをコールします。
 *
 * @param registerRequest スタンプ検索のリクエストボディ
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post(
    "/regist-setting-ticket",
    registerRequest
  );
  return response.data as CommonResponse;
}
