import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { DetailRequest, RegisterRequest } from "@/api/setting-ticket/request";
import SettingTicketDetail from "@/store/setting-ticket/detail";
import SettingTicketRegister from "@/store/setting-ticket/register";
import Loading from "@/store/loading";
import Flash, { ErrorAlert } from "@/store/common/flash";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow
  }
})
export default class Setting extends Mixins(RedirectWithAlert) {
  @Prop({ required: true })
  shopId!: number;

  // タイトル
  headingMain = "デジタルチケット";
  headingSub = "Digital ticket";

  // FIXME: getterにしないと、this.shopNameが取得できない
  get breadCrumbs() {
    return [
      { text: "アピール", disabled: true },
      { text: "デジタルチケット", disabled: true },
      {
        text: "店舗一覧",
        disabled: false,
        to: { name: "ticket" }
      },
      {
        text: "デジタルチケット一覧",
        disabled: false,
        to: {
          name: "ticket-list",
          params: { shopId: String(this.shopId) },
          query: { shopName: this.shopName }
        }
      },
      { text: "デジタルチケット設定", disabled: true }
    ];
  }

  inputParams = {
    /** 店舗ID */
    shopId: 0,
    /** チケット設定ID */
    id: null,
    /** 問い合わせ受付可能日時 */
    inquiryDate: "",
    /** お問い合わせ受付電話番号 */
    inquiryNumber: "",
    /** JCB利用 1:利用する、2:利用しない */
    jcbFlg: 2
  } as RegisterRequest;

  writeFlg = 0;

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  get loading() {
    return Loading.isLoading;
  }

  async created() {
    await SettingTicketDetail.detail({
      shopId: Number(this.shopId)
    } as DetailRequest);
    if (SettingTicketDetail.isSuccess) {
      this.syncInputParams();
    } else {
      await Flash.setErrorNow({
        message: SettingTicketDetail.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  async beforeDestroy() {
    await SettingTicketDetail.clearResponse();
    await SettingTicketRegister.clearResponse();
  }

  async save() {
    await SettingTicketRegister.register(this.inputParams);
    if (SettingTicketRegister.isSuccess) {
      await this.redirectWithSuccessAlert(
        "デジタルチケット設定を保存しました。",
        `/digital-ticket/${this.shopId}/list`,
        { shopName: this.shopName }
      );
    } else {
      await Flash.setErrorNow({
        message: SettingTicketRegister.getMessage,
        showReloadButton: false
      });
    }
  }

  private syncInputParams() {
    const detail = { ...SettingTicketDetail.getDetail };
    this.writeFlg = detail.writeFlg;
    delete detail.writeFlg;
    this.inputParams = { ...detail, shopId: Number(this.shopId) };
  }
}
